<!-- <template>
  <div class="practice-container">
    <PracticePage :practiceList="practiceList" :buttonList="buttonList" />
  </div>
</template>
<script>
import PracticePage from "@/components/Course/CoursePage/PracticePage";
export default {
  data() {
    return {
      practiceList: [
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-right.svg"),
              isRight: true,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-wrong.svg"),
              isRight: false,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 2,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
      ],
            buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi1.svg"),
          startNumberRange: 1,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi2.svg"),
          startNumberRange: 3,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi3.svg"),
          startNumberRange: 5,
          endNumberRange: 6,
        },
      ],
    };
  },
  components: {
    PracticePage,
  },
};
</script>
<style lang="scss" scoped>
.practice-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style> -->
<template>
  <div class="recognition-page-three">
    <PhotoGame
      :imgList="imgList"
      :buttonList="buttonList"
      :titleInfo="titleInfo"
      @changeTheBgImg="changeTheBgImg"
    />
  </div>
</template>

<script>
import PhotoGame from "@/components/Course/GamePage/PhotoGame";
export default {
  data() {
    return {
      imgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/photo-1-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/photo-1-2.svg"),
          hasClicked: false,
          position: 0,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/photo-2-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/photo-2-2.svg"),
          hasClicked: false,
          position: 1,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/photo-3-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/photo-3-2.svg"),
          hasClicked: false,
          position: 0,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/photo-4-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/photo-4-2.svg"),
          hasClicked: false,
          position: 1,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-22/menu-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
      titleInfo: {
        pinyin: "Qǐng búyào shuōhuà！ ",
        hanzi: "请不要说话！",
      },
    };
  },
  components: {
    PhotoGame,
  },
  methods: {
    changeTheBgImg(index) {
      this.imgList[index - 1].hasClicked = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-three {
  width: 100%;
  height: 100%;
}
</style>