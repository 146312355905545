<!-- <template>
  <div class="practice-container">
    <PracticePage :practiceList="practiceList" :buttonList="buttonList" />
  </div>
</template>
<script>
import PracticePage from "@/components/Course/CoursePage/PracticePage";
export default {
  data() {
    return {
      practiceList: [
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-right.svg"),
              isRight: true,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-wrong.svg"),
              isRight: false,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 2,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
      ],
            buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi1.svg"),
          startNumberRange: 1,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi2.svg"),
          startNumberRange: 3,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi3.svg"),
          startNumberRange: 5,
          endNumberRange: 6,
        },
      ],
    };
  },
  components: {
    PracticePage,
  },
};
</script>
<style lang="scss" scoped>
.practice-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style> -->
<template>
  <div class="game-container">
    <MagnifierGame :optionList="optionList" :bgImg="bgImg" :lessonNo="25" :title="title"/>
  </div>
</template>

<script>
import MagnifierGame from "@/components/Course/GamePage/MagnifierGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-25/book-background.svg"),
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-25/book-ren-1.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-25/book-ren-2.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-25/book-ren-3.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-25/book-ren-4.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-25/book-ren-5.svg"),
          // number: 10,
          // showObj: true,
        },
      ],
      title:{
        pinyin:"Shéi zài shuōhuà?",
        hanzi:"谁在说话？"
      }
    };
  },
  components: {
    MagnifierGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>