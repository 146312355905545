<!-- <template>
  <div class="summary-container">
    <SummaryPage
      :summaryTitle="summaryTitle"
      :summaryList="summaryList"
      :lessonNum="3"
    />
  </div>
</template>

<script>
import SummaryPage from "@/components/Course/CoursePage/SummaryPage";
export default {
  data() {
    return {
      lessonNum: 5,
      summaryTitle: {
        pinyin: "Nǐ hǎo! Lǎoshi hǎo!",
        hanzi: "你好！老师好！",
      },
      summaryList: [
        {
          pinyin: "yī",
          hanzi: "一",
          // imgUrl: require("@/assets/img/16-GAMES/profile-dongdong.svg"),
          bgColor: 1,
        },
        {
          pinyin: "èr",
          hanzi: "二",
          // imgUrl: require("@/assets/img/16-GAMES/title-xiaoai.svg"),
          bgColor: 2,
        },
        {
          pinyin: "sān",
          hanzi: "三",
          // imgUrl: require("@/assets/img/16-GAMES/profile-bb2.svg"),
          bgColor: 3,
        },
        {
          pinyin: "sì",
          hanzi: "四",
          // imgUrl: require("@/assets/img/16-GAMES/profile-friend.svg"),
          bgColor: 1,
        },
        {
          pinyin: "wǔ",
          hanzi: "五",
          // imgUrl: require("@/assets/img/16-GAMES/profile-bb.svg"),
          bgColor: 3,
        }
      ],
    };
  },
  components: {
    SummaryPage,
  },
};
</script>
<style lang="scss" scoped>
.summary-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style> -->
<template>
  <div class="summary-container">
    <SummaryPage :lessonNo="5" />
  </div>
</template>

<script>
import SummaryPage from "@/components/Course/CoursePage/SummaryPage";
export default {
  components: {
    SummaryPage,
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  width: 100%;
  height: 100%;
}
</style>
