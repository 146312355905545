<!-- <template>
  <div class="practice-container">
    <PracticePage :practiceList="practiceList" :buttonList="buttonList" />
  </div>
</template>
<script>
import PracticePage from "@/components/Course/CoursePage/PracticePage";
export default {
  data() {
    return {
      practiceList: [
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-right.svg"),
              isRight: true,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-wrong.svg"),
              isRight: false,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 2,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
      ],
            buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi1.svg"),
          startNumberRange: 1,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi2.svg"),
          startNumberRange: 3,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi3.svg"),
          startNumberRange: 5,
          endNumberRange: 6,
        },
      ],
    };
  },
  components: {
    PracticePage,
  },
};
</script>
<style lang="scss" scoped>
.practice-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style> -->
<template>
  <div class="lesson-container">
    <DialogPage
      :bgImg="bgImg"
      :pageInfoList="pageInfoList"
      :buttonList="buttonList"
      @clickTheBubble="clickTheBubble"
      :clickIdList="clickIdList"
      :titleInfo="titleInfo"
      :lessonNo="49"
      :isAllScreen="true"
    />
  </div>
</template>

<script>
import DialogPage from "@/components/Course/CoursePage/DialogPage";
export default {
  data() {
    return {
      clickIdList:[2,4,6,7,8,9,10],
      bgImg: require("@/assets/img/03-Backgrounds/level-1-background-blue.svg"),
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-49/menu-1.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-49/menu-2.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-49/menu-3.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-49/menu-4.svg"),
          isCircle: true,
          startNumberRange: 4,
          endNumberRange: 4,
        },
         {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-49/menu-5.svg"),
          isCircle: true,
          startNumberRange: 5,
          endNumberRange: 5,
        },
        
      ],
      pageInfoList: [
        {
          index: 1,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-49/image-1.svg"),
          singlePageList: [
          
            {
              id: 1,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-ta-she-qu-nar.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-ta-she-qu-nar.svg"),
              isShowAnser: true,
            },
            {
              id: 2,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-1.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 2,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-49/image-2.svg"),
          singlePageList: [
           
            {
              id: 3,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-ta-he-qu-nar.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-ta-he-qu-nar.svg"),
              isShowAnser: true,
            },
            {
              id: 4,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-2.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 3,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-49/image-3.svg"),
          singlePageList: [
           
            {
              id: 5,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-ta-he-qu-nar.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-ta-he-qu-nar.svg"),
              isShowAnser: true,
            },
             {
              id: 6,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-3.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 4,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-49/image-4.svg"),
          singlePageList: [
            
            {
              id: 7,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-ta-she-qu-nar.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-question.svg"),
              isShowAnser: false,
            },
             {
              id: 8,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-4.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 5,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-49/image-5.svg"),
          singlePageList: [
            
            {
              id: 9,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-ta-he-qu-nar.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 10,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-5.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-49/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        
      ],
      titleInfo:{
        pinyin:"qù nǎr？",
        hanzi:"……去哪儿？"
      }
    };
  },
  components: {
    DialogPage,
  },
  methods: {
    clickTheBubble(index, id) {
      this.pageInfoList[index - 1].singlePageList.forEach((item) => {
        if (item.id === id) {
          item.isShowAnser = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>