<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-69.mp3"),
      },
      timeStampList: [4.1, 8.4, 13.0, 21.8, 24.6],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Bāozi,bāozi,zhēn hǎochī.",
            chineseWords: "包子，包子，真好吃。",
          },
          {
            id: 2,
            spelling: "Niúnǎi,niúnǎi,zhēn hǎohē.",
            chineseWords: "牛奶，牛奶，真好喝。",
          },
          {
            id: 3,
            spelling: "    Xū,qǐng búyào shuōhuà！ ",
            chineseWords: "嘘，请不要说话！",
          },
          {
            id: 4,
            spelling: "Ō!Duìbuqǐ,duìbuqǐ.",
            chineseWords: "噢! 对不起，对不起。 ",
          },
          {
            id: 5,
            spelling: "Méi guānxi,méiguānxi.",
            chineseWords: "没关系，没关系。",
          }
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
// .singpage-container {
//   width: calc(100% - 20px);
//   height: calc(100% - 40px);
// }
</style>
